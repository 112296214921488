export default [
	{
		icon: "bi-filetype-jpg",
		name: "jpg",
		contentType: "image/jpeg"
	},
	{
		icon: "bi-filetype-jpg",
		name: "jpeg",
		contentType: "image/jpeg"
	},
	{
		icon: "bi-filetype-png",
		name: "png",
		contentType: "image/png"
	},
	{
		icon: "bi-filetype-png",
		name: "webp",
		contentType: "image/webp"
	}
];
