export default [
	{
		name: 'gpt-4o-mini',
		provider: 'openai',
		promptTypes: ['Text', 'Document', 'ImageVision'],
		fullName:'openai gpt-4o-mini',
		caption: 'Smaller, optimized version for enhanced efficiency'
	},
	{
		name: 'gpt-4o',
		provider: 'openai',
		promptTypes: ['Text', 'Document', 'ImageVision'],
		fullName:'openai gpt-4o',
		caption: 'Advanced model with analytical and creative capabilities'
	},
	{
		name: 'dall-e-3',
		provider: 'openai',
		promptTypes: ['ImageGen'],
		fullName:'openai dall-e-3',
		caption: 'Image generation based on textual descriptions'
	},
	{
		name: 'gemini-1.5',
		provider: 'google',
		promptTypes: ['Text', 'Document', 'ImageVision'],
		fullName:'google gemini-1.5',
		caption: 'Advanced model with analytical and creative capabilities'
	},
	{
		name: '3-haiku',
		provider: 'claude',
		promptTypes: ['Text', 'Document', 'ImageVision'],
		fullName:'claude 3-haiku',
		caption: 'Ability to create short and beautiful poetry'
	}
];
