import toast from './toast'
import security from './security'
import deviceType from './device-type'
import string from './string'
import array from './array'
import url from './url'
import persian from './persian'
import print from './print'
import dataUrl from './data-url'
import guid from './guid'

export default {
	toast,
	security,
	deviceType,
	string,
	array,
	persian,
	print,
	dataUrl,
	guid
}
	
export { toast, security, deviceType, string, array, persian, url, print, dataUrl, guid }
