import { aiModels } from '@/core/data/statics'
// initial state
const state = () => ({
	languages: aiModels,
	selectedModel: null
})


// actions
const actions = {

	setSelectedModel({ commit }, value)
	{
		commit('setSelectedModel', value);
	},

	setSelectedModelFromFullName({ commit }, value)
	{
		commit('setSelectedModelFromFullName', value);
	},
}

// getters
const getters = {

	getLanguagesByPromptType: (state) => (promptType) =>
	{
		return state.languages.filter(language =>
			language.promptTypes.includes(promptType)
		);
	},
}

// mutations
const mutations = {

	setSelectedModel(state, value)
	{
		state.selectedModel = value
	},

	setSelectedModelFromFullName(state, value)
	{
		const index = state.languages.findIndex((x) =>
		{
			return x.fullName == value
		});

		if (index > -1)
		{
			state.selectedModel = state.languages[index]
		}
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
