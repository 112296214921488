<template>
	<router-view :key="$store.state.app.language" />
</template>
<script>
	export default {
		mounted()
		{
			if ("virtualKeyboard" in navigator)
			{
				if (navigator.virtualKeyboard.overlaysContent != true)
				{
					navigator.virtualKeyboard.overlaysContent = true;
					navigator.virtualKeyboard.addEventListener("geometrychange", (event) =>
					{
						const { x, y, width, height } = event.target.boundingRect;

						if (document.activeElement && document.activeElement.nodeName == 'TEXTAREA')
						{

							document.querySelectorAll('.static-input-el').forEach((x) =>
							{
								x.style.bottom = `${height}px`
							});

							document.querySelector('.route-content').style.paddingBottom = `${height}px`
						}
						else
						{
							document.querySelectorAll('.static-input-el').forEach((x) =>
							{
								x.style = ''
							});

							document.querySelector('.route-content').style = ''
						}
					});
				}
			}
		}
	}
</script>
