const print =
{
	printElementById: (id) =>
	{
		var styleCodes = '';
		var styleLinks = [];
		var styles = document.querySelectorAll("style");
		var content = document.getElementById(id).innerHTML

		var load = function (w)
		{
			setTimeout(function () { w.print(); w.close();  }, 500)
		}

		for (var i = 0; i < styles.length; i++)
		{
			styleCodes += styles[i].outerHTML;
		}


		var links = document.querySelectorAll("link");
		for (var i = 0; i < links.length; i++)
		{
			const link = links[i];
			if (link.getAttribute("rel") == "stylesheet" && link.id != "BootstrapCss")
			{
				styleLinks.push(`<link rel="stylesheet" type="text/css" href="${window.location.origin}${link.getAttribute("href") }">`)
			}
		}

		var mywindow = window.open('', 'PRINT');
		mywindow.document.write('<html><head><title> kaveh print</title>');
		mywindow.document.write('<link id="BootstrapCss" rel="stylesheet" href="' + window.location.origin + '/lib/bootstrap/css/bootstrap.rtl.min.css" />');
		mywindow.document.write(styleCodes);

		for (var i = 0; i < styleLinks.length; i++)
		{
			mywindow.document.write(styleLinks[i]);
		}

		mywindow.document.write(`<style>
			@media print {
				@page {
					size: A4;
				}
				body{
					font-size:13px !important;
				}
				table {
					page-break-inside: avoid;
					font-size:13px !important;
				}
			}
		</style >`);
		mywindow.document.write('</head><body dir="rtl">');
		mywindow.document.write(content);
		mywindow.document.write('</body></html>');
		mywindow.focus(); // necessary for IE >= 10*/

		mywindow.addEventListener("load", load(mywindow));

	},
}

export default print


