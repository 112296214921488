
// initial state
const state = () => ({
	layout: null,
	language: null,
	color: null,
	direction: null,
	colors: ["1", "2"],
	modules: [],
	mode: "ligth",
	resource: null,
	overrideResource:null,
	config: null,
	alarm: {},
	pushNotificationKey: null
})


// actions
const actions = {

	setLayout({ commit }, value)
	{
		commit('setlayout', value);
	},

	setColor({ commit }, value)
	{
		commit('setColor', value);
	},

	setNextColor({ state, commit })
	{
		const length = state.colors.length - 1;
		const index = state.colors.findIndex((x) =>
		{
			return x === state.color
		});

		commit('setColor', state.colors[(index < length ? index + 1 : 0)])
	},

	setLanguage({ commit }, value)
	{
		commit('setLanguage', value);
	},

	setDirection({ commit }, value)
	{
		commit('setDirection', value);
	},

	setModules({ commit }, modules)
	{
		commit('setModules', modules);
	},

	setResource({ commit }, resource)
	{
		commit('setResource', resource);
	},

	setOverrideResource({ commit }, overrideResource)
	{
		commit('setOverrideResource', overrideResource);
	},

	setMode({ commit }, mode)
	{
		commit('setMode', mode);
	},

	setConfig({ commit }, config)
	{
		commit('setConfig', config);
	},

	setAlarm({ commit }, value)
	{
		commit('setAlarm', value);
	},

	setPushNotificationKey({ commit }, value)
	{
		commit('setPushNotificationKey', value);
	},
}

// getters
const getters = {
}

// mutations
const mutations = {

	setColor(state, value)
	{
		state.color = value
		localStorage.setItem("kaveh.app.color", value)
		document.body.setAttribute("data-color", value)
	},

	setlayout(state, value)
	{
		state.layout = value
		localStorage.setItem("kaveh.app.layout", value);
	},

	setLanguage(state, value)
	{
		state.language = value
		localStorage.setItem("kaveh.app.language", value)
	},

	setDirection(state, value)
	{
		state.direction = value
		document.body.dir = value
		localStorage.setItem("kaveh.app.direction", value);

		document.querySelector("link#BootstrapCss").href =
			value == 'rtl' ?
			`${process.env.BASE_URL}lib/bootstrap/css/bootstrap.rtl.min.css` :
			`${process.env.BASE_URL}lib/bootstrap/css/bootstrap.min.css`;
	},

	setModules(state, modules)
	{
		state.modules = modules
	},

	setResource(state, resource)
	{
		state.resource = resource
	},

	setOverrideResource(state, overrideResource)
	{
		state.overrideResource = overrideResource;
	},

	setMode(state, mode)
	{
		state.mode = mode;
		document.body.setAttribute("data-mode", mode)
		localStorage.setItem("kaveh.app.mode", mode)
		document.querySelector('meta[name="theme-color"]').setAttribute('content', mode == 'light' ? '#ffffff' : '#1F1F1F');
	},

	setConfig(state, config)
	{
		state.config = config
	},

	setAlarm(state, {name, counter })
	{
		state.alarm[name] = counter
	},

	setPushNotificationKey(state, identifier)
	{
		state.pushNotificationKey = identifier
		localStorage.setItem("kaveh.app.pnk", identifier);
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
