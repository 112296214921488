import { createApp, defineAsyncComponent } from 'vue'

import './assets/fonts/iransans-x/fontiran.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import "toastify-js/src/toastify.css"
import './assets/css/toastr-custom.css'

import './assets/css/app.css'
import './assets/css/themes/default.css'
import './assets/css/popper-theme.css'
import 'swiper/css'
import './registerServiceWorker'

import App from './App.vue'
import launcher from './launcher'


const modules = []


const app = createApp(App)
app.component('kaveh-popup', defineAsyncComponent(() => import(/* webpackChunkName: "com" */  './components/popup/popup')));
app.component('kaveh-button', defineAsyncComponent(() => import(/* webpackChunkName: "com" */  './components/button/button')));
app.component('kaveh-icon-bootstrap', defineAsyncComponent(() => import(/* webpackChunkName: "com" */  './components/icon/icon-bootstrap')));
app.component('kaveh-loading', defineAsyncComponent(() => import(/* webpackChunkName: "com" */  './components/common/common-loading')));
app.component('kaveh-overlay', defineAsyncComponent(() => import(/* webpackChunkName: "com" */  './components/overlay/overlay')));
app.component('kaveh-camera', defineAsyncComponent(() => import(/* webpackChunkName: "com" */  './components/camera/camera')));
app.component('popper', defineAsyncComponent(() => import(/* webpackChunkName: "com" */  'vue3-popper')));

launcher.launch(app, modules)


